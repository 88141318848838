<template>
  <div class="container-label">
    <div class="header d-flex justify-content-between">
      <a-row class="d-flex align-items-center">
        <a-col :span="3" class="d-flex justify-content-start">
          <div style="cursor: pointer" @click="back()">
            <ArrowIcon
              class="ant-dropdown-link"
              style="color: #E00000; height: 100px; width: 25px"
              :rotate="'rotate(270)'"
            />
          </div>
        </a-col>
        <a-col
          class="d-flex justify-content-start align-items-center"
          :span="24"
          style="border-left: 1px solid rgb(204, 204, 204)"
        >
          <div
            class="ml-4"
            style="color: #006BD1; margin-right: 5px"
          >
            Invoice List >
          </div>
          Invoice Detail
        </a-col>
      </a-row>
      <div class="mr-2">
        <a-button class="btn-primary" @click.prevent="printLabel">
          Cetak Invoice
        </a-button>
      </div>
    </div>
    <div class="page">
      <div class="content px-4 py-4" style="position: relative;">
        <div class="d-flex justify-content-between mb-4">
          <div class="text-left mb-2">
            <div class="logo">
              <img
                :src="distributorDetail?.logoUrl || invoiceLogo"
                class="mr-2"
                alt="Clean UI"
              />
            </div>
          </div>
          <div style="display: flex; flex-direction: column; align-items: flex-end">
            <span class="title-text">INVOICE</span>
            <span class="desc-text-blue">{{ invoiceNumber }}</span>
          </div>
        </div>
        <div class="d-flex justify-content-between mb-4">
          <div class="text-left mb-2 w-50">
            <div class="text-dark mb-1 title-text">
              DITERBITKAN ATAS NAMA
            </div>
            <div class="d-flex">
              <div class="w-25 desc-text-1">
                Penjual
              </div>
              <div class="desc-text-1 mr-1">
                :
              </div>
              <div v-if="!orderDetail?.dropshipper" class="w-75 desc-text-2 ml-2">
                {{ sellerDetail && sellerDetail.name || '' }}
              </div>
              <div v-else class="w-75 desc-text-2 ml-2">
                {{ `${orderDetail?.dropshipper?.name}, ${orderDetail?.dropshipper?.phone}` }}
              </div>
            </div>
          </div>

          <div class="text-left mb-2 w-50">
            <div class="text-dark mb-1 title-text">
              UNTUK
            </div>
            <div class="d-flex mb-1">
              <div class="destination-title desc-text-1 mr-3">
                Pembeli
              </div>
              <div class="desc-text-1 mr-1">
                :
              </div>
              <div class="destination-desc desc-text-2">
                {{ getFullName }}
              </div>
            </div>
            <div class="d-flex mb-1">
              <div class="destination-title desc-text-1 mr-3">
                No Order
              </div>
              <div class="desc-text-1 mr-1">
                :
              </div>
              <div class="destination-desc desc-text-2">
                {{ invoiceDetail && invoiceDetail.order_number }}
              </div>
            </div>
            <div class="d-flex mb-1">
              <div class="destination-title desc-text-1 mr-3">
                Tanggal Order
              </div>
              <div class="desc-text-1 mr-1">
                :
              </div>
              <div class="destination-desc desc-text-2">
                {{ getOrderDate }}
              </div>
            </div>
            <div class="d-flex mb-1">
              <div class="destination-title desc-text-1 mr-3">
                Alamat
              </div>
              <div class="desc-text-1 mr-1">
                :
              </div>
              <div class="destination-desc desc-text-2">
                {{ getAddress }}
              </div>
            </div>
            <div class="d-flex mb-1">
              <div class="destination-title desc-text-1 mr-3">
                No Telepon
              </div>
              <div class="desc-text-1 mr-1">
                :
              </div>
              <div class="destination-desc desc-text-2">
                {{ getPhoneNumber }}
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center">
          <table style="width:100%;">
            <tr class="text-left title-text" style="border-bottom: 2px solid #000000; border-top: 2px solid #000000; line-height: 50px">
              <th class="header-product">
                PRODUK
              </th>
              <th class="header-qty">
                JUMLAH
              </th>
              <th class="header-price">
                HARGA SATUAN
              </th>
              <th class="header-total">
                TOTAL HARGA
              </th>
            </tr>
            <tr v-for="(item, index) in invoiceDetail && invoiceDetail.invoice_items" :key="index" class="text-left tr-product-list" style=" border-bottom: 1px solid #F4F4F4;">
              <td class="desc-text-blue header-product" style="vertical-align: top;">
                {{ item.catalog_title || '' }} *
              </td>
              <td class="desc-text-3 header-qty" style="vertical-align: top;">
                {{ item.quantity || 0 }} ({{ item.unit || '' }})
              </td>
              <td class="desc-text-3 header-price" style="vertical-align: top; ">
                <div class="desc-product-discount-text mb-2" :style="item && item.promotions && item.promotions.discounts.length > 0 && 'text-decoration: line-through;'">
                  Rp. {{ toCurrency(item.selling_price) }}
                </div>
                <div v-for="(obj, idx) in item && item.promotions && item.promotions.discounts" :key="idx" class="desc-product-discount-text d-flex justify-content-between mt-1" style="gap: 0px 10px;">
                  <div class="disc-per-product-tag">
                    {{ renderElementTagDiscountPerProduct(obj, item) }}
                  </div>
                  <div class="disc-per-product-value">
                    {{ renderElementValueDiscountPerProduct(idx, item) }}
                  </div>
                </div>
                <div v-for="(obj, idx) in item && item.promotions && item.promotions.vouchers" :key="idx" class="desc-product-discount-text d-flex justify-content-between mt-1" style="gap: 0px 10px;">
                  <div class="disc-per-product-tag">
                    {{ renderElementTagDiscountPerProduct(obj, item) }}
                  </div>
                  <div class="disc-per-product-value">
                    {{ renderElementValueDiscountPerProduct(idx, item) }}
                  </div>
                </div>
              </td>
              <td class="desc-text-blue header-total" style="vertical-align: top;">
                Rp. {{ toCurrency(item.sub_total) }}
              </td>
            </tr>
          </table>
        </div>

        <div class="d-flex">
          <div style="width: 35%" />
          <div class="mt-3 mb-3" style="width: 65%">
            <div class="d-flex justify-content-between price-border-bottom">
              <div class="text-left price-text-title">
                TOTAL HARGA ({{ invoiceDetail && invoiceDetail.invoice_items.length || 0 }} PRODUK)
              </div>
              <div class="text-right price-text-title" style="width: 140px">
                Rp. {{ toCurrency(orderDetail && orderDetail.sub_total) }}
              </div>
            </div>
            <div v-for="({ name, promotion_value, gift_value, value_type }, index) in discountTransactionList" :key="index" class="d-flex justify-content-between price-border-bottom">
              <div class="text-left price-text-2">
                {{ name }}{{ value_type.toUpperCase() === "PERCENTAGE" ? ` (${gift_value}%)` : '' }}
              </div>
              <div class="text-right price-text-2" style="width: 140px">
                Rp. {{ toCurrency(promotion_value) }}
              </div>
            </div>
            <div v-for="({ name, promotion_value, gift_value, value_type }, index) in voucherTransactionList" :key="index" class="d-flex justify-content-between price-border-bottom">
              <div class="text-left price-text-2">
                {{ name }}{{ value_type.toUpperCase() === "PERCENTAGE" ? ` (${gift_value}%)` : '' }}
              </div>
              <div class="text-right price-text-2" style="width: 140px">
                Rp. {{ toCurrency(promotion_value) }}
              </div>
            </div>
            <div v-if="orderDetail && orderDetail.loyalty_usage" class="d-flex justify-content-between price-border-bottom">
              <div class="text-left price-text-1">
                Koin
              </div>
              <div class="text-right price-text-2" style="width: 140px">
                {{ toCurrency(orderDetail.loyalty_usage) }}
              </div>
            </div>
            <div v-if="orderDetail && orderDetail.payment_data && orderDetail.payment_data.credit_limit_usage" class="d-flex justify-content-between price-border-bottom">
              <div class="text-left price-text-1">
                Credit Limit
              </div>
              <div class="text-right price-text-2" style="width: 140px">
                -Rp. {{ toCurrency(orderDetail.payment_data.credit_limit_usage) }}
              </div>
            </div>
            <div v-if="invoiceDetail && invoiceDetail.deposit" class="d-flex justify-content-between price-border-bottom">
              <div class="text-left price-text-1">
                Deposit Retur
              </div>
              <div class="text-right price-text-2" style="width: 140px">
                - {{ toCurrency(invoiceDetail.deposit) }}
              </div>
            </div>
            <div v-if="deliveryCost > 0" class="d-flex justify-content-between price-border-bottom">
              <div class="text-left w-50" style="font-size: 13px">
                Biaya Pengiriman
              </div>
              <div class="text-right w-50" style="font-size: 13px">
                Rp. {{ toCurrency(deliveryCost) }}
              </div>
            </div>
            <div v-if="orderDetail && orderDetail.total_vat" class="d-flex justify-content-between price-border-bottom">
              <div class="text-left price-text-1">
                PPN
              </div>
              <div class="text-right price-text-2" style="width: 140px">
                Rp. {{ toCurrency(orderDetail.total_vat) }}
              </div>
            </div>
            <div class="d-flex justify-content-between" style="lineHeight: 35px;">
              <div class="text-left price-text-title">
                TOTAL TAGIHAN
              </div>
              <div class="text-right desc-text-orange" style="width: 140px">
                Rp. {{ toCurrency(orderDetail && (orderDetail.gross_total - (orderDetail.payment_data.credit_limit_usage ?? 0))) }}
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 text-left disclaimer-ppn">
          {{ '*) Harga termasuk PPN' }}
        </div>
        <a-divider />
        <div class="d-flex">
          <div class="text-left mt-3 mb-0 w-50">
            <div class="mb-1 desc-text-1">
              Kurir
            </div>
            <div class="d-flex">
              <div class="w-75 desc-text-2">
                <span v-if="orderDetail?.shipment_data?.courier !== 'OWN'">{{ orderDetail?.shipment_data?.courier }} - </span>
                {{ orderDetail?.shipment_data?.service_name }}
              </div>
            </div>
          </div>
          <div class="text-left mt-3 mb-0">
            <div class="mb-1 desc-text-1">
              Metode Pembayaran
            </div>
            <div class="d-flex">
              <div class="w-75 desc-text-2">
                {{ paymentName }}
              </div>
            </div>
          </div>
        </div>

        <div class="text-left mt-4 mb-4 w-50">
          <div class="text-dark mb-1 desc-text-1" v-html="invoiceFooter" />
        </div>

        <div class="invoice-stamp">
          <InvoicePaidIcon v-if="orderDetail && orderDetail.payment_state === 'PAID'" />
          <InvoiceUnpaidIcon v-else />
        </div>
      </div>
      <div class="page-breaker" />
    </div>
  </div>
</template>

<script>
import InvoicePaidIcon from '@/components/Icons/InvoicePaid.vue'
import InvoiceUnpaidIcon from '@/components/Icons/InvoiceUnpaid.vue'
import { mapState } from 'vuex'
import ArrowIcon from '@/components/Icons/Arrow.vue'
import debounce from 'lodash/debounce'
import { getDetailDistributor, getFinanceDetail, getPaymentDetail } from '@/api/channels/distributor'
import { getInvoiceDetail } from '@/api/invoice'
import { getBusinessMetaData } from '@/api/business'
import { getWarehouseInternal } from '@/api/warehouse'

export default {
  name: 'DetailInvoice',
  components: {
    InvoicePaidIcon,
    InvoiceUnpaidIcon,
    ArrowIcon,
    
  },
  data: () => ({
    orderDetail: null,
    invoiceDetail: null,
    sellerDetail: null,
    paymentName: '',
    discountTransactionList: null,
    discountPerProductList: null,
    defaultDiscountPrice: 0,
    invoiceLogo: null,
    invoiceFooter: 'Invoice ini sah dan diproses oleh komputer. Silahkan hubungi <span style="color: red">Customer Service </span> <br /> apabila Anda membutuhkan bantuan',
    distributorDetail: null,
    invoiceNumber: '',
    deliveryCost: 0,
    voucherTransactionList: [],
  }),
  computed: {
    ...mapState(['account', 'storeSetting']),
    ...mapState('user', {
      store_url: state => state.store_url,
    }),
    getOrderDate() {
      const orderDate = this.invoiceDetail ? this.invoiceDetail.created_date : null
      return orderDate ? this.$moment(orderDate).format('DD - MMMM - YYYY') : ''
    },
    getAddress() {
      const userAddress = this.orderDetail ? this.orderDetail.destination_data : null
      return userAddress ? `${userAddress.address}, ${userAddress.district}, ${userAddress.country}, ${userAddress.postal_code}` : ''
    },
    getPhoneNumber() {
      return this.orderDetail ? this.orderDetail.destination_data.phone.slice(0, -3) + 'XXX' : ''
    },
    getFullName() {
      return this.orderDetail ? this.orderDetail.customer_info.name : ''
    },
  },
  mounted () {
    this.getBusinessId()
    this.fetchInvoiceDetail()
    this.fetchPaymentDetail()
    this.fetchBusinessMetaData()
  },
  methods: {
    async getBusinessId() {
      return await this.$store.state.user.businessList?.[0]?.business_id
    },
    async fetchBusinessMetaData() {
      const businessId = this.$route.query.business_id  || await this.getBusinessId()
      await getBusinessMetaData({ business_id: businessId })
      .then(({ data: response }) => {
        response.forEach((item) => {
          if (item.meta_name === 'invoice_logo') {
            this.invoiceLogo = item.meta_value
          }
          if (item.meta_name === 'invoice_footer') {
            this.invoiceFooter = item.meta_value
          }
        })
      })
      .catch(err => {
        console.error(err)
      })
    },
    fetchInvoiceDetail: debounce(async function() {
      const businessId = this.$route.query.business_id  || await this.getBusinessId()

      await getInvoiceDetail({
        id: this.$route.query.order_id,
        business_id: businessId,
      })
      .then(async ({ data: { data: response } }) => {
        this.invoiceDetail = response
        this.discountTransactionList = response?.promotions?.discounts ?? []
        this.discountPerProductList = response?.invoice_items ?? []
        this.fetchDetailDistributor()

        await getFinanceDetail({
          order_id: this.$route.query.order_id,
          channel_id: this.$route.params.id,
          business_id: businessId,
        })
        .then(async ({ data: { data: response } }) => {
          this.orderDetail = response

          await getWarehouseInternal({
            business_id: businessId,
            params: { warehouse_id: [response.warehouse_id].join(',') },
          })
          .then(({ data: { data: response } }) => this.sellerDetail = response[0] ?? null)
        })
        .catch(err => console.log(err))
      })
      .catch(err => console.log(err))
    }, 500),
    fetchPaymentDetail: debounce(async function() {
      const businessId = this.$route.query.business_id  || await this.getBusinessId()
      await getPaymentDetail({
        order_id: this.$route.query.order_id,
        channel_id: this.$route.params.id,
        business_id: businessId,
      })
      .then(({ data: { data: response } }) => this.paymentName = response && response.payment_name ? response.payment_name : '-')
    }, 500),
    getMetaInvoice: debounce(async function() {
      const businessId = this.$route.query.business_id  || await this.getBusinessId()
      await getBusinessMetaData({ business_id: businessId })
      .then(({ data: response }) => {
        this.metaInvoiceLogo = response.find(item => item.meta_name === 'invoice_logo').meta_value
        this.metaInvoiceFooter = response.find(item => item.meta_name === 'invoice_footer').meta_value
      })
    }, 500),
    printLabel () {
      window.print()
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
    renderElementTagDiscountPerProduct(obj) {
      if (obj.value_type.toUpperCase() === 'PERCENTAGE') {
        return `${obj.gift_value}%`
      } else {
        return `Rp. ${this.toCurrency(obj.gift_value)}`
      }
    },
    renderElementValueDiscountPerProduct(index, dataListItem) {
      let sellingPrice = dataListItem?.selling_price
      /* eslint-disable no-unused-expressions */
      dataListItem?.promotions?.discounts.map((obj, idx) => {
        if (idx <= index) {
          sellingPrice = sellingPrice - (obj?.promotion_value / dataListItem?.quantity)
        }
      })
      return `Rp. ${this.toCurrency(sellingPrice)}`
    },
    back() {
      this.$router.push({
        name: 'finance-channel.invoice',
        query: {
          ...this.$route.query,
          order_id: undefined,
        },
      })
    },
    async fetchDetailDistributor() {
      await getDetailDistributor(this.invoiceDetail.seller_id)
        .then(({ data: { data: response } }) => this.distributorDetail = response)
        .catch(() => this.distributorDetail = null)
    },
  },
}
</script>

<style lang="scss">

.container-label {
  width: 100%;
  background-color: white;

  .header {
    width: 100%;
    padding: .75rem;
  }

  .page {
    text-align: center;
    margin-top: 2rem;

    .content {
      width: 50%;
      border: 1px solid black;
      // margin-top: 1rem;
      margin: auto;
    }

    .page-breaker {
      // position: relative;
      // text-align: center;
      page-break-before: always;
      // margin-bottom: 20px;
    }
  }

  .btn-primary {
    color: white;
    border: 1px solid #015289;
    background-color: #015CA1;
    border-radius: 8px;
  }
  .logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;
      img {
        height: 55px;
        object-fit: cover;
      }
    }
}

@media print {
    body * {
      visibility: hidden;
    }
    .page * {
      visibility: visible;
    }
  .container-label {
    position: fixed;
    margin: 0;
    left: 0;
    top: 0;
    .header {
      > div {
        display: none;

      }
    }
    .page {
      .content {
        width: 70%;
      }
    }
  }
}

.title-text {
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  color: #000000;
}

.desc-text-1 {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: #767676;
}

.desc-text-2 {
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  color: #000000;
}

.desc-text-3 {
  font-size: 13px;
  font-weight: 400;
  color: #000000;
}

.desc-product-discount-text {
  font-size: 13px;
  font-weight: 400;
  color: #000000;
  line-height: 16px;
}

.desc-text-blue {
  font-size: 13px;
  font-weight: 500;
  color: #6cc8eb;
}

.desc-text-orange {
  font-size: 16px;
  font-weight: 700;
  color: #F5831F;
}

.price-text-title {
  font-size: 13px;
  font-weight: 600;
  color: #000000;
}

.price-text-1 {
  font-size: 13px;
  font-weight: 400;
  color: #767676;
}

.price-text-2 {
  font-size: 13px;
  font-weight: 400;
  color: #000000;
}

.price-border-bottom {
  line-height: 35px;
  border-bottom: 1px solid #F4F4F4;
}

.invoice-stamp {
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 0;
  opacity: 0.08;
}

.tr-product-list td {
  padding: 20px 0px;
}

.disc-per-product-tag {
  background-color: #FFF2F2;
  border-radius: 2px;
  padding: 3px;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #FF0000;
}

.disclaimer-ppn {
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #767676;
  font-style: italic;
}
.disc-per-product-value {
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.header-product {
  padding-right: 15px;
  width: 40%;
}
.header-qty {
  padding-left: 15px;
  padding-right: 15px!important;
  width: 10%;
  text-align: right;
}
.header-price {
  padding-left: 15px;
  width: 30%;
  text-align: right;
}
.header-total {
  padding-left: 15px;
  width: 20%;
  text-align: right;
}
.destination-title {
  width: 30%;
}
.destination-desc {
  width: 70%;
}
</style>